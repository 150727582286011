import type { contract } from '@samsys/shared';
import type { TsRestClient } from './ts-rest.service';
import {
  apiHandler,
  type InferBody,
  type InferFlatRequest
} from '@/utils/ts-rest';

export const createCanbusCounterConfigService = ({
  tsRestClient
}: {
  tsRestClient: TsRestClient;
}) => {
  return {
    getProfiles(
      query: InferFlatRequest<typeof contract.canbusCounterConfig.profiles>
    ) {
      return apiHandler(tsRestClient.canbusCounterConfig.profiles, { query });
    },
    createProfile(
      body: InferBody<typeof contract.canbusCounterConfig.createProfile>
    ) {
      return apiHandler(tsRestClient.canbusCounterConfig.createProfile, {
        body
      });
    },
    updateProfile({
      profileId,
      ...body
    }: InferFlatRequest<typeof contract.canbusCounterConfig.updateProfile>) {
      return apiHandler(tsRestClient.canbusCounterConfig.updateProfile, {
        body,
        params: { profileId }
      });
    },
    assignProfile({
      profileId,
      ...body
    }: InferFlatRequest<typeof contract.canbusCounterConfig.assignProfile>) {
      return apiHandler(tsRestClient.canbusCounterConfig.assignProfile, {
        body,
        params: { profileId }
      });
    }
  };
};
