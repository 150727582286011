import { queryKeys } from '@/utils/queryKeys';
import { ONE_MINUTE_IN_MS, type FeatureFlagsResponse } from '@samsys/shared';

export const useFeatureFlags = () => {
  const { data } = useApiQuery(
    queryKeys.FEATURE_FLAGS,
    api => api.featureFlagService.getFeatureFlags,
    {
      staleTime: Infinity,
      refetchInterval: ONE_MINUTE_IN_MS * 60
    }
  );

  return data.value!;
};

export const useFeatureFlagsGuard = () => {
  const router = useRouter();
  const qc = useQueryClient();

  const isEnabled = (ff: unknown) => {
    if (!ff) return true;
    if (!Array.isArray(ff)) {
      console.warn(
        `Route feature flags need to be an array', ${typeof ff} received`
      );
      return true;
    }

    const flags = qc.getQueryData<FeatureFlagsResponse>(
      queryKeys.FEATURE_FLAGS
    );
    if (!flags) {
      console.warn('feature flags not loaded');
      return true;
    }
    return ff.every(flag => {
      // @ts-ignore dunno
      if (!(flag in flags) && flags[flag] === false) {
        console.warn('unknwn feature flag', flag);
        return true;
      }

      return flags[flag as keyof FeatureFlagsResponse] === true;
    });
  };

  router.beforeEach((to, from, next) => {
    const ff = to.meta.featureFlags;
    if (isEnabled(ff)) {
      return next();
    } else {
      return next({
        name: 'Home'
      });
    }
  });

  router.isReady().then(() => {
    if (!isEnabled(router.currentRoute.value.meta.featureFlags)) {
      setTimeout(() => {
        router.push({ name: 'Home' });
      }, 100);
    }
  });
};
