import { createActivityService } from '@/services/activity.service';
import { createActivityBookingService } from '@/services/activity-booking.service';
import { createAuthService } from '@/services/auth.service';
import { createClusterService } from '@/services/cluster.service';
import { createDataboxService } from '@/services/databox.service';
import { createDataboxAssociationService } from '@/services/databox-association.service';
import { createFeatureFlagService } from '@/services/feature-flags.service';
import { createFieldService } from '@/services/field.service';
import { createHttpService } from '@/services/http.service';
import { createMachineService } from '@/services/machine.service';
import { createMeService } from '@/services/me.service';
import { createNotificationService } from '@/services/notification.service';
import { createPingService } from '@/services/ping.service';
import { createUserService } from '@/services/user.service';
import { createWebsocketService } from '@/services/websocket.service';
import { createFavoriteFilterService } from '@/services/favorite-filter.service';
import { createWidgetService } from '@/services/widget.service';
import { createTsDatadefService } from '@/services/ts-datadef.service';
import { createDashboardService } from '@/services/dashboard.service';
import { createTsRestService } from '@/services/ts-rest.service';
import { createAreaService } from '@/services/areas.service';
import { createMaintenanceAlertService } from '@/services/maintenance-alert.service';
import { createMaintenanceTaskService } from '@/services/maintenance-task.service';
import { createMaintenanceProfileService } from '@/services/maintenance-profile.service';
import { createMaintenanceActionService } from '@/services/maintenance-action.service';
import { createActivityTemplateService } from '@/services/activity-template.service';
import { createBleTagService } from '@/services/ble-tag.service';
import { createFileExportService } from '@/services/file-export.service';
import { createCanbusValuesConfigService } from '@/services/canbus-values-config.service';
import { createStaticDashboardService } from '@/services/static-dashboard.service';
import { createBillingService } from '@/services/billing-account.service';
import { createCanbusCounterConfigService } from '@/services/canbus-counter-config.service';
import { createGeoNoteService } from '@/services/geo-note.service';
import { createS3UploadService } from '@/services/s3-upload.service';

export type ApiClient = {
  socket: ReturnType<typeof createWebsocketService>;
  httpService: ReturnType<typeof createHttpService>;
  authService: ReturnType<typeof createAuthService>;
  tsRestClient: ReturnType<typeof createTsRestService>;
  featureFlagService: ReturnType<typeof createFeatureFlagService>;
  pingService: ReturnType<typeof createPingService>;
  activityBookingService: ReturnType<typeof createActivityBookingService>;
  clusterService: ReturnType<typeof createClusterService>;
  meService: ReturnType<typeof createMeService>;
  userService: ReturnType<typeof createUserService>;
  machineService: ReturnType<typeof createMachineService>;
  fieldService: ReturnType<typeof createFieldService>;
  fileExportService: ReturnType<typeof createFileExportService>;
  dataBoxService: ReturnType<typeof createDataboxService>;
  databoxAssociationService: ReturnType<typeof createDataboxAssociationService>;
  notificationService: ReturnType<typeof createNotificationService>;
  activityService: ReturnType<typeof createActivityService>;
  activityTemplateService: ReturnType<typeof createActivityTemplateService>;
  favoriteFilterService: ReturnType<typeof createFavoriteFilterService>;
  widgetService: ReturnType<typeof createWidgetService>;
  dashboardService: ReturnType<typeof createDashboardService>;
  tsDatadefService: ReturnType<typeof createTsDatadefService>;
  areaService: ReturnType<typeof createAreaService>;
  maintenanceAlertService: ReturnType<typeof createMaintenanceAlertService>;
  maintenanceTaskService: ReturnType<typeof createMaintenanceTaskService>;
  maintenanceProfileService: ReturnType<typeof createMaintenanceProfileService>;
  maintenanceActionService: ReturnType<typeof createMaintenanceActionService>;
  bleTagService: ReturnType<typeof createBleTagService>;
  staticDashboardService: ReturnType<typeof createStaticDashboardService>;
  canbusValuesConfigService: ReturnType<typeof createCanbusValuesConfigService>;
  billingService: ReturnType<typeof createBillingService>;
  canbusCounterConfigService: ReturnType<
    typeof createCanbusCounterConfigService
  >;
  geoNoteService: ReturnType<typeof createGeoNoteService>;
  s3UploadService: ReturnType<typeof createS3UploadService>;
};

export const createApiClient = ({
  getLang
}: {
  getLang: () => string;
}): ApiClient => {
  const httpService = createHttpService({ getLang });
  const tsRestClient = createTsRestService({ httpService });
  const authService = createAuthService({ httpService, tsRestClient });

  const socket = createWebsocketService({ httpService, authService });
  const s3UploadService = createS3UploadService({ tsRestClient });

  return {
    socket,
    httpService,
    authService,
    tsRestClient,
    s3UploadService,
    featureFlagService: createFeatureFlagService({ httpService }),
    pingService: createPingService({ httpService }),
    activityBookingService: createActivityBookingService({
      tsRestClient,
      socket
    }),
    clusterService: createClusterService({ tsRestClient }),
    meService: createMeService({ tsRestClient }),
    userService: createUserService({ tsRestClient }),
    machineService: createMachineService({ tsRestClient }),
    fieldService: createFieldService({ tsRestClient }),
    fileExportService: createFileExportService({ httpService, tsRestClient }),
    dataBoxService: createDataboxService({ tsRestClient, socket }),
    databoxAssociationService: createDataboxAssociationService({
      tsRestClient
    }),
    notificationService: createNotificationService({ tsRestClient }),
    activityService: createActivityService({ tsRestClient }),
    activityTemplateService: createActivityTemplateService({
      tsRestClient
    }),
    favoriteFilterService: createFavoriteFilterService({ tsRestClient }),
    widgetService: createWidgetService({ tsRestClient }),
    dashboardService: createDashboardService({ tsRestClient, socket }),
    tsDatadefService: createTsDatadefService({ tsRestClient }),
    areaService: createAreaService({ tsRestClient }),
    maintenanceAlertService: createMaintenanceAlertService({ tsRestClient }),
    maintenanceTaskService: createMaintenanceTaskService({ tsRestClient }),
    maintenanceProfileService: createMaintenanceProfileService({
      tsRestClient
    }),
    maintenanceActionService: createMaintenanceActionService({ tsRestClient }),
    bleTagService: createBleTagService({ tsRestClient }),
    canbusValuesConfigService: createCanbusValuesConfigService({
      tsRestClient
    }),
    staticDashboardService: createStaticDashboardService({ tsRestClient }),
    billingService: createBillingService({ tsRestClient, httpService }),
    canbusCounterConfigService: createCanbusCounterConfigService({
      tsRestClient
    }),
    geoNoteService: createGeoNoteService({ tsRestClient, s3UploadService })
  };
};
