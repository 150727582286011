import {
  ASSET_TAG_FILTER_PREFIX,
  type AnyObject,
  type contract,
  type Override
} from '@samsys/shared';
import type { TsRestClient } from './ts-rest.service';
import {
  apiHandler,
  type InferBody,
  type InferFlatRequest,
  type InferParams,
  type InferQuery
} from '@/utils/ts-rest';

type WithoutTagFilter<T extends { filter: AnyObject }> = Override<
  T,
  {
    filter: Omit<T['filter'], 'tags'>;
  }
>;

export const createMachineService = ({
  tsRestClient
}: {
  tsRestClient: TsRestClient;
}) => {
  return {
    create(body: InferBody<typeof contract.machine.createMachine>) {
      return apiHandler(tsRestClient.machine.createMachine, { body });
    },

    update({
      machineId,
      ...body
    }: InferFlatRequest<typeof contract.machine.updateMachine>) {
      return apiHandler(tsRestClient.machine.updateMachine, {
        params: { machineId },
        body
      });
    },

    getAll(query: WithoutTagFilter<InferQuery<typeof contract.machine.all>>) {
      const normalizedFilters: InferQuery<typeof contract.area.all>['filter'] =
        {
          tags: {}
        };
      for (const [name, filter] of Object.entries(query.filter)) {
        if (!normalizedFilters.tags) {
          normalizedFilters.tags = {};
        }

        if (name.startsWith(ASSET_TAG_FILTER_PREFIX)) {
          const key = name.replace(ASSET_TAG_FILTER_PREFIX, '');
          normalizedFilters.tags[key] = filter;
        } else {
          // @ts-expect-error argh !
          normalizedFilters[name] = filter;
        }
      }

      return apiHandler(tsRestClient.machine.all, {
        query: { ...query, filter: normalizedFilters }
      });
    },

    getList(query: InferQuery<typeof contract.machine.list>) {
      return apiHandler(tsRestClient.machine.list, {
        query
      });
    },

    getAllDetailled(
      query: WithoutTagFilter<InferQuery<typeof contract.machine.allDetailled>>
    ) {
      const normalizedFilters: InferQuery<
        typeof contract.machine.allDetailled
      >['filter'] = {
        tags: {}
      };
      for (const [name, filter] of Object.entries(query.filter)) {
        if (!normalizedFilters.tags) {
          normalizedFilters.tags = {};
        }

        if (name.startsWith(ASSET_TAG_FILTER_PREFIX)) {
          const key = name.replace(ASSET_TAG_FILTER_PREFIX, '');
          normalizedFilters.tags[key] = filter;
        } else {
          // @ts-expect-error re-argh !
          normalizedFilters[name] = filter;
        }
      }

      return apiHandler(tsRestClient.machine.allDetailled, {
        query: { ...query, filter: normalizedFilters }
      });
    },

    getAssociations({
      machineId,
      ...query
    }: InferFlatRequest<typeof contract.machine.associations>) {
      return apiHandler(tsRestClient.machine.associations, {
        params: { machineId },
        query
      });
    },

    getAvailableTags() {
      return apiHandler(tsRestClient.machine.tags, {});
    },

    getAvailableHashtags() {
      return apiHandler(tsRestClient.machine.hashtags, {});
    },

    getById(params: InferParams<typeof contract.machine.byId>) {
      return apiHandler(tsRestClient.machine.byId, { params });
    },

    getByIdDetailled(
      params: InferParams<typeof contract.machine.byIdDetailled>
    ) {
      return apiHandler(tsRestClient.machine.byIdDetailled, { params });
    },
    getGeolocation(
      params: InferParams<typeof contract.machine.geolocationById>
    ) {
      return apiHandler(tsRestClient.machine.geolocationById, { params });
    },

    getGeolocationBatch(
      query: InferQuery<typeof contract.machine.geolocation>
    ) {
      if (!query.machineIds.length) return Promise.resolve([]);
      return apiHandler(tsRestClient.machine.geolocation, { query });
    },

    getCategories() {
      return apiHandler(tsRestClient.machine.categories, {});
    },

    getMachineCountByCategory() {
      return apiHandler(tsRestClient.machine.categoriesCount, {});
    },

    getBrands() {
      return apiHandler(tsRestClient.machine.brands, {});
    },

    getSchedule(params: InferParams<typeof contract.machine.schedule>) {
      return apiHandler(tsRestClient.machine.schedule, { params });
    },

    editHashtags({
      machineId,
      ...body
    }: InferFlatRequest<typeof contract.machine.editHashtags>) {
      return apiHandler(tsRestClient.machine.editHashtags, {
        params: { machineId },
        body
      });
    },

    editTags({
      machineId,
      ...body
    }: InferFlatRequest<typeof contract.machine.editTags>) {
      return apiHandler(tsRestClient.machine.editTags, {
        params: { machineId },
        body
      });
    },

    async getTsDatas({
      machineId,
      ...body
    }: InferFlatRequest<typeof contract.machine.tsDatas>) {
      return apiHandler(tsRestClient.machine.tsDatas, {
        params: { machineId },
        body
      });
    },

    getUserAccessById(params: InferParams<typeof contract.machine.byId>) {
      return apiHandler(tsRestClient.machine.getUsersAccessMachine, { params });
    },

    deleteUserAccessMachine(
      params: InferParams<typeof contract.machine.deleteUserAccessMachine>
    ) {
      return apiHandler(tsRestClient.machine.deleteUserAccessMachine, {
        params
      });
    },

    updateUserAccessMachine({
      machineId,
      userId,
      ...body
    }: InferFlatRequest<typeof contract.machine.updateUserAccessMachine>) {
      return apiHandler(tsRestClient.machine.updateUserAccessMachine, {
        params: { machineId, userId },
        body
      });
    },

    addUsersAccessMachine({
      machineId,
      ...body
    }: InferFlatRequest<typeof contract.machine.addUsersAccessMachine>) {
      return apiHandler(tsRestClient.machine.addUsersAccessMachine, {
        params: { machineId },
        body
      });
    },
    getRebuiltStatus({
      startDate,
      endDate
    }: InferFlatRequest<typeof contract.machine.rebuildStatus>) {
      return apiHandler(tsRestClient.machine.rebuildStatus, {
        query: { startDate, endDate }
      });
    }
  };
};
