import type { TsRestClient } from './ts-rest.service';
import { apiHandler } from '@/utils/ts-rest';

export type S3UploadService = ReturnType<typeof createS3UploadService>;

export const createS3UploadService = ({
  tsRestClient
}: {
  tsRestClient: TsRestClient;
}) => {
  return {
    uploadMultiple(files: File[]) {
      return apiHandler(tsRestClient.s3Uploads.uploadMultiple, {
        body: { files }
      });
    }
  };
};
